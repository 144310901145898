import AppDispatcher from '../dispatcher/AppDispatcher';
import WebAPI from '../utils/WebAPI';

import {
  REPLY_SET_INITIAL_DATA,
  REPLY_SELECT_TEMPLATE,
  REPLY_SET_EMAILOUT_CONTENT,
  REPLY_SEND_EMAIL,
  REPLY_SENDING_SUCCESSFUL,
  REPLY_SENDING_ERROR,
} from '../constants/AppConstants';

export default {
  set_initial_data(data) {
    AppDispatcher.dispatch({
      actionType: REPLY_SET_INITIAL_DATA,
      suggested_templates: data.suggested_templates,
      all_templates: data.all_templates,
      greeting: data.greeting,
      close: data.close,
      emailout_uuid: data.emailout_uuid,
      emailout_content: data.emailout_content,
    });
  },

  select_template(template_uuid) {
    AppDispatcher.dispatch({
      actionType: REPLY_SELECT_TEMPLATE,
      template_uuid: template_uuid
    });
  },

  set_emailout_content(emailout_content) {
    AppDispatcher.dispatch({
      actionType: REPLY_SET_EMAILOUT_CONTENT,
      emailout_content: emailout_content
    });
  },

  send_email() {
    AppDispatcher.dispatch({
      actionType: REPLY_SEND_EMAIL
    });
  },

  // Server interaction
  server_send_email(emailout_uuid, emailout_content, selected_template_uuid) {
    WebAPI.send_email(emailout_uuid, emailout_content, selected_template_uuid)
    .then((data) => {
      AppDispatcher.dispatch({
        actionType: REPLY_SENDING_SUCCESSFUL,
      });
    })
    .catch((data) => {
      AppDispatcher.dispatch({
        actionType: REPLY_SENDING_ERROR,
      });
    });
  },

};

