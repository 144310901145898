export const REPLY_UPDATED = 'REPLY_UPDATED';
export const REPLY_SET_INITIAL_DATA = 'REPLY_SET_INITIAL_DATA';
export const REPLY_SELECT_TEMPLATE = 'REPLY_SELECT_TEMPLATE';
export const REPLY_SET_EMAILOUT_CONTENT = 'REPLY_SET_EMAILOUT_CONTENT';
export const REPLY_SEND_EMAIL = 'REPLY_SEND_EMAIL';
export const REPLY_SENDING_SUCCESSFUL = 'REPLY_SENDING_SUCCESSFUL';
export const REPLY_SENDING_ERROR = 'REPLY_SENDING_ERROR';

export const REPLY_STATUS_COMPOSE = 'REPLY_STATUS_COMPOSE';
export const REPLY_STATUS_SENDING = 'REPLY_STATUS_SENDING';
export const REPLY_STATUS_SENT = 'REPLY_STATUS_SENT';
