import classNames from 'classnames';
import React from 'react';
import ReplyStore from '../../stores/ReplyStore';
import ReplyActions from '../../actions/ReplyActions';
import Textarea from 'react-textarea-autosize';
import StatusButton from './StatusButton.jsx';


let { Component } = React;


export default class GenericTemplateList extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    var template_html = this.props.templates.map((t) => {
      var item_classnames = classNames({
        c_app_reply__compose__generictemplate: true,
        selected: t.selected
      });
      var _select_template = function () {
        ReplyActions.select_template(t.uuid);
      };
      if(t.suggested) {
        var prefix = '🔥 ';
      } else {
        var prefix = '';
      }
      return <div className={item_classnames} key={t.uuid} onClick={_select_template}><h3>{prefix}{t.title}</h3><p>{t.text}</p></div>
    });
    return(
      <div className="c_app_reply__compose__generictemplate__list">
        {template_html}
      </div>
    );
  }
}



