import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import ReplyApp from './components/reply/ReplyApp.jsx';


$(document).ready(function() {
  var app_reply_container = $('#js_app__reply');
  if(app_reply_container.length > 0) {
    ReactDOM.render(
      React.createElement(ReplyApp),
      document.getElementById('js_app__reply')
    );
  }
});

