import $ from 'jquery';

export default class WebAPI {

  static send_email(emailout_uuid, emailout_content, selected_template_uuid) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/v1/web/send_email/${emailout_uuid}/`,
        method: 'POST',
        data: JSON.stringify({
          emailout_content: emailout_content,
          selected_template_uuid: selected_template_uuid,
        }),
        dataType: 'json',
        contentType: 'application/json',
        success: (data) => {
          resolve(data);
        },
        error: (data) => {
          reject(data);
        }
      });
    });
  }

}

