import $ from 'jquery';
import autosize from 'autosize';
import frontend from './frontend.js';


function fix_footer(footer, footer_top_initial) {
  var footer_height = footer.outerHeight();
  var footer_top = footer.offset().top;
  var viewport_height = $(window).height();
  var new_margin_top = Math.max(0, Math.floor(viewport_height - footer_top_initial - footer_height + 10));
  footer.css('margin-top', new_margin_top);
}

$(document).ready(function() {
  // make menu responsive
  let menu = $('.c_menu__responsive_menu ul.c_menu__main_nav');
  let toggle = $('.c_menu__responsive_menu .c_menu__responsive_menu_toggle');
  toggle.on('click', function(event) {
    event.preventDefault();
    menu.slideToggle();
  });
  autosize($('textarea.js_autosized_textarea'));
  var footer = $('.js_fixed_to_bottom');
  var footer_top_initial = footer.offset().top;
  fix_footer(footer, footer_top_initial);
  $(window).resize(() => {
    fix_footer(footer, footer_top_initial);
  });
});

