import classNames from 'classnames';
import React from 'react';
import ReplyStore from '../../stores/ReplyStore';
import ReplyActions from '../../actions/ReplyActions';
import Textarea from 'react-textarea-autosize';
import StatusButton from './StatusButton.jsx';
import GenericTemplateList from './GenericTemplateList.jsx';

import {
  REPLY_STATUS_COMPOSE,
  REPLY_STATUS_SENDING,
  REPLY_STATUS_SENT,
} from '../../constants/AppConstants';


let { Component } = React;

function getState() {
  return {
    suggested_templates: ReplyStore.getSuggestedTemplates(),
    generic_templates: ReplyStore.getAllTemplates(),
    emailout_content: ReplyStore.getEmailoutContent(),
    greeting: ReplyStore.getGreeting(),
    close: ReplyStore.getClose(),
    status: ReplyStore.getStatus(),
    has_sending_error: ReplyStore.hasSendingError(),
  };
}


export default class ReplyApp extends Component {

  constructor(props) {
    super(props);
    this.state = Object.assign({
      show_generic_templates: false
    }, getState());
    this._onChange = this._onChange.bind(this)
    this._toggleShowGenericTemplates = this._toggleShowGenericTemplates.bind(this)
    this._setEmailoutContent = this._setEmailoutContent.bind(this)
  }

  componentDidMount() {
    ReplyStore.addChangeListener(this._onChange);
    ReplyActions.set_initial_data({
      suggested_templates: DATA.suggested_templates,
      all_templates: DATA.all_templates,
      greeting: DATA.greeting,
      close: DATA.close,
      emailout_uuid: DATA.emailout_uuid,
      emailout_content: DATA.emailout_content,
    });
  }

  componentWillUnmount() {
    ReplyStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getState());
  }

  _toggleShowGenericTemplates(e) {
    e.preventDefault();
    this.setState({
      show_generic_templates: !this.state.show_generic_templates
    });
  }

  _setEmailoutContent(e) {
    ReplyActions.set_emailout_content(e.target.value);
  }

  _sendEmail() {
    ReplyActions.send_email();
  }

  render() {
    var suggested_templates = this.state.suggested_templates.map((st) => {
      var item_classnames = classNames({
        c_app_reply__compose__predictedreplies__template: true,
        selected: st.selected
      });
      var _select_template = function () {
        ReplyActions.select_template(st.uuid);
      };
      return <span className={item_classnames} key={st.uuid} onClick={_select_template}><h3>{st.title}</h3><p>{st.text}</p></span>
    });
    var close_br = this.state.close.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    });
    if(this.state.status === REPLY_STATUS_COMPOSE) {
      var content_element = <Textarea
        className="c_app_reply__compose__message__content c_app_reply__compose__message__content__edit"
        value={this.state.emailout_content}
        onChange={this._setEmailoutContent}
        minRows={3}
      />
    } else {
      var content_element = <div className="c_app_reply__compose__message__content">{this.state.emailout_content}</div>
    }
    if(this.state.show_generic_templates) {
      var all_templates_html = <GenericTemplateList templates={this.state.generic_templates} onSelectTemplate={null} />
      var show_templates_link_text = 'Hide template list'
    } else {
      var all_templates_html = '';
      var show_templates_link_text = 'Select another template'
    }
    if(this.state.suggested_templates.length > 0) {
      var templates_heading = 'Predicted Replies';
      var templates_link = <a href="#" className="c_app_reply__compose__templates__selectother" onClick={this._toggleShowGenericTemplates}>{show_templates_link_text}</a>
    } else {
      var templates_heading = 'Templates';
      var templates_link = '';
      var all_templates_html = <GenericTemplateList templates={this.state.generic_templates} onSelectTemplate={null} />
    }
    return(
      <div>
        <div className="c_app_reply__compose__templates">
          <h2 className="c_app_reply__h2">{templates_heading}</h2>
          <div className="c_app_reply__compose__predictedreplies">{suggested_templates}</div>
          {templates_link}
          {all_templates_html}
        </div>

        <div className="c_app_reply__heading_and_button">
          <h2 className="c_app_reply__h2 c_app_reply__heading_and_button__left">Your Reply</h2>
          <StatusButton
            status={this.state.status}
            has_sending_error={this.state.has_sending_error}
            onClick={this._sendEmail}
          />
        </div>
        <div className="c_app_reply__compose__message">
          <div className="c_app_reply__compose__message__greeting">{this.state.greeting}</div>
          {content_element}
          <div className="c_app_reply__compose__message__close">{close_br}</div>
        </div>
      </div>
    );
  }
}



