import AppDispatcher from '../dispatcher/AppDispatcher';
import ReplyActions from '../actions/ReplyActions';
import _ from 'underscore'
import { EventEmitter } from 'events';

import {
  REPLY_UPDATED,
  REPLY_SET_INITIAL_DATA,
  REPLY_SELECT_TEMPLATE,
  REPLY_SET_EMAILOUT_CONTENT,
  REPLY_SEND_EMAIL,
  REPLY_SENDING_SUCCESSFUL,
  REPLY_SENDING_ERROR,
  REPLY_STATUS_COMPOSE,
  REPLY_STATUS_SENDING,
  REPLY_STATUS_SENT,
} from '../constants/AppConstants';


class ReplyStore extends EventEmitter {

  constructor(...args) {
    super(...args);
    this.templates = [];
    this.greeting = '';
    this.close = '';
    this.emailout_content = '';
    this.status = REPLY_STATUS_COMPOSE;
    this.has_sending_error = false;
  }

  emitChange() {
    this.emit(REPLY_UPDATED);
  }

  addChangeListener(callback) {
    this.on(REPLY_UPDATED, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(REPLY_UPDATED, callback);
  }

  // GETTER
  getSuggestedTemplates() {
    return this.templates.filter((t) => {
      return t.suggested;
    });
  }

  getGenericTemplates() {
    return this.templates.filter((t) => {
      return !t.suggested;
    });
  }

  getAllTemplates() {
    return this.templates;
  }

  getSelectedTemplateUuid() {
    var result = this.templates.find((t) => {
      return t.selected;
    });
    if(result) {
      return result.uuid;
    } else {
      return null;
    }
  }

  getGreeting() {
    return this.greeting;
  }

  getClose() {
    return this.close;
  }

  getEmailoutUuid() {
    return this.emailout_uuid;
  }

  getEmailoutContent() {
    return this.emailout_content;
  }

  getStatus() {
    return this.status;
  }

  hasSendingError() {
    return this.has_sending_error;
  }

  // SETTER & SELECT
  setInitialData(data) {
    var processed_uuids = [];
    data.suggested_templates.forEach((t) => {
      t.suggested = true;
      if(!processed_uuids.includes(t.uuid)) {
        this.templates.push(t);
        processed_uuids.push(t.uuid);
      }
    });
    data.all_templates.forEach((t) => {
      t.suggested = false;
      if(!processed_uuids.includes(t.uuid)) {
        this.templates.push(t);
        processed_uuids.push(t.uuid);
      }
    });
    this.greeting = data.greeting;
    this.close = data.close;
    this.emailout_uuid = data.emailout_uuid;
    this.emailout_content = data.emailout_content;
    // Use text of selected template as emailout_content
    this.templates.forEach((t) => {
      if(t.selected) {
        this.emailout_content = t.text;
      }
    });
    this.emitChange();
  }

  selectTemplate(uuid) {
    this.templates.forEach((t) => {
      if(t.uuid === uuid) {
        t.selected = true;
        this.emailout_content = t.text;
      } else {
        t.selected = false;
      }
    });
    this.emitChange();
  }

  setEmailoutContent(content) {
    this.emailout_content = content;
    this.emitChange();
  }

  setStatus(status, has_error=false) {
    this.status = status;
    this.has_sending_error = has_error;
    this.emitChange();
  }

}


let store = new ReplyStore();


AppDispatcher.register((action) => {
  switch(action.actionType) {
    case REPLY_SET_INITIAL_DATA:
      store.setInitialData(action);
      break;
    case REPLY_SELECT_TEMPLATE:
      store.selectTemplate(action.template_uuid);
      break;
    case REPLY_SET_EMAILOUT_CONTENT:
      store.setEmailoutContent(action.emailout_content);
      break;
    case REPLY_SEND_EMAIL:
      store.setStatus(REPLY_STATUS_SENDING, false);
      ReplyActions.server_send_email(store.getEmailoutUuid(), store.getEmailoutContent(), store.getSelectedTemplateUuid());
      break;
    case REPLY_SENDING_SUCCESSFUL:
      store.setStatus(REPLY_STATUS_SENT, false);
      break;
    case REPLY_SENDING_ERROR:
      store.setStatus(REPLY_STATUS_COMPOSE, true);
      break;
    default:
  }
});

export default store;

