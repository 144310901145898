import classNames from 'classnames';
import React from 'react';

import {
  REPLY_STATUS_COMPOSE,
  REPLY_STATUS_SENDING,
  REPLY_STATUS_SENT,
} from '../../constants/AppConstants';


let { Component } = React;


export default class StatusButton extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    switch(this.props.status) {
      case REPLY_STATUS_SENDING:
        return(
          <span className="c_app_reply__heading_and_button__right">
            <span className="c_app_reply__heading_and_button__right__statusmessage">
              <span className="c_loaders__shockwave c_app_reply__heading_and_button__right__statusmessage__spinner">
                <span className="c_loaders__shockwave__figure"></span>
              </span>
              <span className="c_app_reply__heading_and_button__right__statusmessage__text">
                Sending email...
              </span>
            </span>
          </span>
        );
        break;
      case REPLY_STATUS_SENT:
        return(
          <span className="c_app_reply__heading_and_button__right">
            <span className="c_app_reply__heading_and_button__right__statusmessage">
              <span className="c_app_reply__heading_and_button__right__statusmessage__text">
                ✔︎ Email was sent.
              </span>
            </span>
          </span>
        );
        break;
      default:
        if(this.props.has_sending_error) {
        var error = 
          <span className="c_app_reply__heading_and_button__right__statusmessage">
            <span className="c_app_reply__heading_and_button__right__statusmessage__error">
              There was an error. Please try sending again.
            </span>
          </span>
        } else {
          var error = null;
        }
        return(
          <span className="c_app_reply__heading_and_button__right">
            {error}
            <button className="c_button" onClick={this.props.onClick}>Send</button>
          </span>
        );
        break;
    }
  }
}



